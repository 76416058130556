import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import emailjs from '@emailjs/browser'
import fbLogo from '../../assets/icons8-facebook-50.png'
import githubLogo from '../../assets/icons8-github-50.png'
import goodReadsLogo from '../../assets/icons8-goodreads-50.png'
import igLogo from '../../assets/icons8-instagram-50.png'
import linkedinLogo from '../../assets/icons8-linkedin-50.png'
import mediumLogo from '../../assets/icons8-medium-50.png'
import twitterLogo from '../../assets/icons8-twitter-50.png'

function ContactSection () {
  const blankForm = {
    first: '',
    last: '',
    email: '',
    message: ''
  }
  const [disabled, setDisabled] = useState(false)
  const [form, setForm] = useState(blankForm)
  const [status, setStatus] = useState('send')
  function handleChange (e) {
    setForm({...form,
      [e.target.name]: e.target.value
    })
  }
  function handleSubmit (e) {
    e.preventDefault()
    setDisabled(true)
    setStatus('sending...')
    emailjs.send('default_service', 'template_xzplmeq', form, 'l6isIWV_Gz7FNUw3n').then(
      function(response) {
        console.log('SUCCESS!', response.status, response.text)
        setStatus('sent!')
        setTimeout(() => {
          setDisabled(false)
          setForm(blankForm)
          setStatus('send')
        }, 3000)
      }, function(error) {
        console.log('FAILED...', error)
        setStatus('error')
        setTimeout(() => {
          setDisabled(false)
          setStatus('send')
        }, 3000)
      }
    )
  }

  const socials = [
    {
      name: 'Medium',
      img: mediumLogo,
      url: 'https://medium.com/@bvcage',
    },
    {
      name: 'LinkedIn',
      img: linkedinLogo,
      url: 'https://www.linkedin.com/in/bvcage/',
    },
    {
      name: 'GitHub',
      img: githubLogo,
      url: 'https://github.com/bvcage',
    },
    {
      name: 'GoodReads',
      img: goodReadsLogo,
      url: 'https://www.goodreads.com/bvc94',
    },
    // {
    //   name: 'Instagram',
    //   img: igLogo,
    //   url: 'https://www.instagram.com/bvc.94/',
    // },
    // {
    //   name: 'Twitter',
    //   img: twitterLogo,
    //   url: 'https://twitter.com/bvc_94',
    // },
    // {
    //   name: 'Facebook',
    //   img: fbLogo,
    //   url: 'https://www.facebook.com/bvc.94',
    // },
  ]

  return (
    <Container fluid id='contact' className='homepage-section'>
      <Container className='section-content'>
        <Row>
          <Col xs={12}>
            <Container className='contact-container'>
              <h2>contact</h2>
            </Container>
          </Col>
          <Col xs={12}>
            <Container className='contact-container'>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col className='pe-2'>
                    <Form.Control
                      type='text'
                      name='first'
                      placeholder='first'
                      disabled={disabled}
                      value={form.first}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col className='ps-2'>
                    <Form.Control
                      type='text'
                      name='last'
                      placeholder='last'
                      disabled={disabled}
                      value={form.last}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Control
                      type='email'
                      name='email'
                      placeholder='email'
                      disabled={disabled}
                      value={form.email}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Control
                      as='textarea'
                      name='message'
                      placeholder='message'
                      disabled={disabled}
                      rows={4}
                      value={form.message}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs='auto'>
                  </Col>
                  <Col></Col>
                  <Col xs='auto'>
                    <Button
                      variant='outline-secondary'
                      type='reset'
                      className='me-2'
                      disabled={disabled || Object.entries(form).toString() === Object.entries(blankForm).toString()}
                      onClick={() => setForm(blankForm)}
                      >clear
                    </Button>
                    <Button
                      variant='secondary'
                      type='submit'
                      disabled={disabled || !form.message || !form.email}
                      >{status}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Col>
          <Col xs={12}>
            <Container className='contact-container'>
              <Container>
                <Row className='justify-content-center m-0'>
                  {socials.map(social => {
                    return (
                      <Col key={social.name} xs='auto'>
                        <a href={social.url} target='_blank' rel='noreferrer'>
                          <img src={social.img} alt={`${social.name} logo`} />
                        </a>
                      </Col>
                    )
                  })}
                </Row>
              </Container>
            </Container>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default ContactSection