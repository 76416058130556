import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'

// firebase setup
import { initializeApp } from 'firebase/app'
const firebaseConfig = {
  apiKey: 'AIzaSyAnG_jSTH4VnE0xOHDRfpLpkudXXXymkas',
  authDomain: 'bvc-portfolio.firebaseapp.com',
  projectId: 'bvc-portfolio',
  storageBucket: 'bvc-portfolio.appspot.com',
  messagingSenderId: '978480326071',
  appId: '1:978480326071:web:862bc78df0894d3e01f432'
}
initializeApp(firebaseConfig);

// core app
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
