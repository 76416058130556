import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import './App.scss'
import HomePage from './pages/HomePage'
import MediaPage from './pages/MediaPage'
import ProjectDetailsPage from './pages/ProjectDetailsPage'
import ProjectsPage from './pages/ProjectsPage'
import ResumePage from './pages/ResumePage'

function App () {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />
  },
  // {
  //   path: 'projects',
  //   children: [
  //     {
  //       path: '',
  //       element: <ProjectsPage />
  //     },
  //     {
  //       path: ':name',
  //       element: <ProjectDetailsPage />
  //     }
  //   ]
  // },
  // {
  //   path: 'resume',
  //   element: <ResumePage />
  // },
  // {
  //   path: 'media',
  //   element: <MediaPage />
  // }
])

export default App
