import React from 'react'
import Container from 'react-bootstrap/Container'
import Stack from 'react-bootstrap/Stack'
import HomeSection from './main/HomeSection'
import MediaSection from './main/MediaSection'
import ProjectsSection from './main/ProjectsSection'
import ResumeSection from './main/ResumeSection'
import Footer from '../components/Footer'
import ContactSection from './main/ContactSection'
import { Col, Row } from 'react-bootstrap'
import NavCol from '../components/NavCol'
import AboutSection from './main/AboutSection'

function HomePage () {
  return (
    <Container fluid className='page p-0'>
      <Row style={{height: '100%'}}>
        <Col>
          <h1>coming soon</h1>
          {/* <NavCol /> */}
        {/* </Col> */}
        {/* <Col> */}
          {/* <Stack gap={0}> */}
            {/* <HomeSection /> */}
            {/* <AboutSection /> */}
            {/* <ProjectsSection /> */}
            {/* <ResumeSection /> */}
            {/* <MediaSection /> */}
            {/* <ContactSection /> */}
            {/* <Footer /> */}
          {/* </Stack> */}
        </Col>
      </Row>
    </Container>
  )
}

export default HomePage